// extracted by mini-css-extract-plugin
export var section = "team-screen-module--section--QVcaN";
export var grid = "team-screen-module--grid--XaEUm";
export var text = "team-screen-module--text--3oIIe";
export var button = "team-screen-module--button--qLjDP";
export var slider = "team-screen-module--slider--OENYa";
export var swiper = "team-screen-module--swiper--s8AmT";
export var sliderNav = "team-screen-module--slider-nav--Mgj-R";
export var active = "team-screen-module--active--N65od";
export var sliderButtonContainer = "team-screen-module--slider-button-container--hYtTc";
export var container = "team-screen-module--container--j57A3";
export var prev = "team-screen-module--prev--oOecP";
export var next = "team-screen-module--next--JtcZU";
export var sliderButton = "team-screen-module--slider-button--pU8tU";