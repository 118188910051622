import * as React from "react"
import * as css from "./media-screen.module.scss"
import { useTranslation } from "../../../common/use-translations/use-translations"

const medias = [
  {
    slug: "tc",
  },
  {
    slug: "urban-list",
  },
  {
    slug: "hypebae",
  },
  {
    slug: "forbes",
  },
  {
    slug: "bustle",
  },
  {
    slug: "beebom",
  },
  {
    slug: "babel",
  },
  {
    slug: "nyt",
  },
  {
    slug: "wellgood",
  },
  {
    slug: "lifewire",
  },
  {
    slug: "refinery",
  },
  {
    slug: "dou",
  },
  {
    slug: "ain",
  },
  {
    slug: "muo",
  },
]

export function MediaScreen() {
  const _ = useTranslation()

  return (
    <section className={css.section}>
      <div className="container">
        <h2 className={css.title}>{_.home_media_title}</h2>
        <div className={css.logos}>
          {medias.map((media, index) => (
            <img
              key={index}
              src={`/media/${media.slug}.svg`}
              alt="Media logo"
            />
          ))}
        </div>
      </div>
    </section>
  )
}
