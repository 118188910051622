import * as React from "react"
import * as css from "./about-screen.module.scss"
import { useTranslation } from "../../../common/use-translations/use-translations"
import { Icon } from "../../../common/icon/icon"

export function AboutScreen() {
  const _ = useTranslation()

  return (
    <section className={css.about}>
      <div className="container overflow-visible">
        <div className={css.grid}>
          <div className={css.products}>
            <a href="https://asknebula.com/" target="_blank" className={css.productLink}>
              <span className={css.productCard}>
                <img src="/products/nebula.png" alt="Nebula" />
                <span>
                    Nebula
                </span>
                <Icon name="arrow-right" className={css.arrow} />
              </span>
            </a>
            {/* <a href="https://asknebula.com/" target="_blank" className={css.productLink}>
              <span className={css.productCard}>
                <img src="/products/nebula.png" alt="Nebula" />
                <span>
                    AskNebula
                </span>
                <Icon name="arrow-right" className={css.arrow} />
              </span>
            </a> */}
          </div>

          <div className={css.text}>
            <span className="uppercase">{_.about_us}</span>
            <h2>{_.we_create_products}</h2>
            <p>{_.home_obrio_description}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
