import * as React from "react"
import { Icon } from "../../common/icon/icon"
import { SocialNetworkUrl } from "../../constants/social-network-url"
import * as css from "./home-page.module.scss"

export function SocialIcons() {
  return (
    <div className={css.socialIcons}>
      <a href={SocialNetworkUrl.LinkedIn} target="_blank">
        <Icon name="linkedin" />
      </a>

      <a href={SocialNetworkUrl.Instagram} target="_blank">
        <Icon name="instagram" />
      </a>

      <a href={SocialNetworkUrl.Facebook} target="_blank">
        <Icon name="facebook" />
      </a>
      
      <a href={SocialNetworkUrl.TikTok} target="_blank">
        <Icon name="tiktok" />
      </a>
    </div>
  )
}
