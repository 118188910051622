import * as React from "react"
import * as css from "./numbers-screen.module.scss"
import { useTranslation } from "../use-translations/use-translations"

export function NumbersScreen() {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <img className={css.deco} src="/deco/circle.svg" alt="svg circle" />
      <div className="container">
        <div className={css.grid}>
          <div className={css.card}>
            <div className={css.title}>50+</div>
            <p className={css.text}>{_.feature_growth}</p>
          </div>

          <div className={css.card}>
            <div className={css.title}>60</div>
            <p className={css.text}>{_.feature_installs}</p>
          </div>

          <div className={css.card}>
            <div className={css.title}>№1</div>
            <p className={css.text}>{_.feature_localizations}</p>
          </div>

          <div className={css.card}>
            <div className={css.title}>4,8</div>
            <p className={css.text}>{_.feature_rating}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
