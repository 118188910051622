import * as React from "react"
import * as css from "./collaborations-carousel-screen.module.scss"
import { useTranslation } from "../../../common/use-translations/use-translations"
import classNames from "classnames";
import { CollaborationsCarouselSlide } from "./collaborations-carousel-slide"
import { collaborationSlideInfoList } from "./collaboration-slide-info-list"
import { SwiperSlide, Swiper } from "swiper/react"
import { useState } from "react"

export function CollaborationsCarouselScreen() {
  const _ = useTranslation();
  // @ts-ignore
  const [slider, setSlider] = useState<Swiper>()
  const [activeSlide, setActiveSlide] = useState(0)
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideClick = (index: number) => {
    if (slider) {
      slider.slideToLoop(index)
    }
  }

  return(
    <section className={css.section}>
      <img className={css.deco} src="/deco/circle.svg" alt="svg circle" />
        <Swiper
          onActiveIndexChange={() => setActiveSlide(slider?.realIndex)}
          onInit={swiper => {
            setSlider(swiper)
            setActiveSlide(0)
          }}
          onSlideChange={() => {
            setIsBeginning(slider?.isBeginning);
            setIsEnd(slider?.isEnd);
          }}
          className={css.slider}
          breakpoints={{
            320: {
              autoHeight: false,
              observer: false,
            },
            768: {
              autoHeight: true,
              observer: true,
            },
            1024: {
              autoHeight: false,
              observer: false,
            },
          }}
        >
          {collaborationSlideInfoList.map((collaboration, index) => (
            <SwiperSlide className={css.slide} key={index}>
              <CollaborationsCarouselSlide collaboration={collaboration} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={css.sliderNavigationContainer}>
          <button
            className={`${css.sliderNavigation} ${css.prev} ${isBeginning ? css.buttonDisabled : css.buttonActive}`}
            onClick={() => slider.slidePrev()}
          >
            <img src="/icons/collab-right.svg" alt="arrow" />
          </button>
          <button
            className={`${css.sliderNavigation} ${css.next} ${isEnd ? css.buttonDisabled : css.buttonActive}`}
            onClick={() => slider.slideNext()}
          >
            <img src="/icons/collab-right.svg" alt="arrow" />
          </button>
        </div>

        <div className={css.sliderNav}>
          {collaborationSlideInfoList.map((slide, index) => (
            <span
              key={index}
              onClick={() => handleSlideClick(index)}
              className={classNames(
                css.button,
                index === activeSlide ? css.active : ""
              )}
            />
          ))}
        </div>
    </section>
  )
}