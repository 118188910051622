import { CollaborationSlide } from "./collaboration-slide";

export const collaborationSlideInfoList: CollaborationSlide[] = [
  {
    title: "freed_title",
    description: "freed_description",
    image: "/collaborations/freed.png",
    imageMb: "/collaborations/freed-mb.png",
    imageTab: "/collaborations/freed-tab.png",
    reverse: false,
  },
  {
    title: "smule_title",
    description: "smule_description",
    image: "/collaborations/smule.png",
    imageMb: "/collaborations/smule-mb.png",
    imageTab: "/collaborations/smule-tab.png",
    reverse: true,
  },
]
