import * as React from "react"
import * as css from "./partners-screen.module.scss"
import Lottie from "lottie-react"
import { useEffect, useState } from "react"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function PartnersScreen() {
  const [animation, setAnimationData] = useState(null)
  const _ = useTranslation()

  useEffect(() => {
    // @ts-ignore
    fetch("/animations/partners.json")
      .then(res => res.json())
      .then(setAnimationData)
  }, [])

  return (
    <section className={css.section}>
      <img className={css.deco} src="/deco/circle.svg" alt="svg circle" />
      <div className="container">
        <div className={css.grid}>
          <div className={css.text}>
            <span className="uppercase">{_.partners_subtitle}</span>
            <h2>{_.partners_title}</h2>
            <p>{_.partners_description}</p>
          </div>
          <div className={css.image}>
            <Lottie
              className={css.animation}
              animationData={animation || null}
            />

            <picture>
              <source media="(max-width: 768px)" srcSet="/partners-mob.png" />
              <img src="/partners.svg" alt="Partners" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}
