// extracted by mini-css-extract-plugin
export var section = "collaborations-carousel-screen-module--section--FH5aR";
export var deco = "collaborations-carousel-screen-module--deco--oEfJL";
export var grid = "collaborations-carousel-screen-module--grid--keaIN";
export var title = "collaborations-carousel-screen-module--title--j01j6";
export var image = "collaborations-carousel-screen-module--image--ETjeA";
export var imageCenter = "collaborations-carousel-screen-module--image-center--7yZ90";
export var background = "collaborations-carousel-screen-module--background--0eyc6";
export var text = "collaborations-carousel-screen-module--text--tsxY4";
export var slider = "collaborations-carousel-screen-module--slider--YKjWE";
export var sliderNav = "collaborations-carousel-screen-module--slider-nav--yFpYR";
export var button = "collaborations-carousel-screen-module--button--t0Q4Z";
export var active = "collaborations-carousel-screen-module--active--YFSka";
export var sliderNavigationContainer = "collaborations-carousel-screen-module--slider-navigation-container--29JC7";
export var prev = "collaborations-carousel-screen-module--prev--J3xIc";
export var next = "collaborations-carousel-screen-module--next--90kXp";
export var sliderNavigation = "collaborations-carousel-screen-module--slider-navigation--8hEZ8";
export var buttonDisabled = "collaborations-carousel-screen-module--button-disabled--rE2IY";
export var buttonActive = "collaborations-carousel-screen-module--button-active--wZ6+X";