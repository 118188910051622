import { WelcomeScreen } from "./welcome-screen/welcome-screen"
import { AboutScreen } from "./about-screen/about-screen"
import { NumbersScreen } from "../../common/numbers-screen/numbers-screen"
import { PartnersScreen } from "./partners-screen/partners-screen"
import { MediaScreen } from "./media-screen/media-screen"
import { TeamScreen } from "./team-screen/team-screen"
import { CollaborationsCarouselScreen } from "./collaborations-carousel-screen/collaborations-carousel-screen"
import { Helmet } from "react-helmet"
import * as React from "react"
import { JoinUsScreen } from "../../common/join-us-screen/join-us-screen"
import { useTranslation } from "../../common/use-translations/use-translations"
import * as css from "./home-page.module.scss"
import { SocialIcons } from "./social-icons"

export function HomePage() {
  const _ = useTranslation()
  return (
    <>
      <Helmet>
        <title>OBRIO</title>
        <link rel="preload" href="/obrio-team.png" as="image" />
      </Helmet>
      <div className={css.stickyScroll}>
        <SocialIcons />
        <WelcomeScreen />
        <AboutScreen />
        <NumbersScreen />
        <PartnersScreen />
        <CollaborationsCarouselScreen />
      </div>
      <MediaScreen />
      <JoinUsScreen ctaClassNames={'home-send-cv-cta'} title={_.home_join_us_title} button={_.join_us_cta} />
      <TeamScreen />
    </>
  )
}
