import { CommonLayout } from "../common/common-layout/common-layout"
import "./../style/common.scss"
import * as React from "react"
import { HomePage } from "../page-components/home/home-page"

export default function Home(props) {
  return (
    <CommonLayout {...props}>
      <HomePage />
    </CommonLayout>
  )
}
