import * as React from "react"
import * as css from "./welcome-screen.module.scss"
import { LinkButton } from "../../../common/button/link-button"
import { Icon } from "../../../common/icon/icon"
import { getLocalizedUrl } from "../../../common/localized-link/localized-link"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function WelcomeScreen() {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <div className={css.background}>
        <img src={"/obrio-team.png"} alt="OBRIO team" />
      </div>
      <div className={css.text}>
        <div className="container">
          <h1 dangerouslySetInnerHTML={{ __html: _.home_h1 }} />

          <LinkButton className={'home-first-screen-cta'} url={getLocalizedUrl("/vacancies")}>
            {_.vacancies}
            <Icon className={css.buttonIcon} name="chevron-right" />
          </LinkButton>
        </div>
      </div>
    </section>
  )
}
