import * as css from "./team-screen.module.scss"
import * as React from "react"
import classNames from "classnames"
import { LinkButton } from "../../../common/button/link-button"
import { SwiperSlide, Swiper } from "swiper/react"
import { useState } from "react"
import { getLocalizedUrl } from "../../../common/localized-link/localized-link"
import { useTranslation } from "../../../common/use-translations/use-translations"
import { useEffect } from "react"
import { Icon } from "../../../common/icon/icon"

const slides = [
  "/team/team-clevel.webp",
  "/team/team-recruters.webp",
  "/team/team-glasses.webp",
  "/team/team-creative.webp",
  "/team/team-conference.webp",
  "/team/team-joke.webp",
  "/team/team-mexico.webp",
  "/team/team-analytics.webp",
  "/team/team-drink.webp",
  "/team/team-dnipro.webp",
]

export function TeamScreen() {
  // @ts-ignore
  const [slider, setSlider] = useState<Swiper>()
  const [activeSlide, setActiveSlide] = useState(0)
  const _ = useTranslation()

  useEffect(() => {
    const handleKeyDown = event => {
      switch(event.key) {
        case 'ArrowLeft':
          slider?.slidePrev();
          break;
        case 'ArrowRight':
          slider?.slideNext();
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [slider])

  return (
    <section className={css.section}>
      <div className={classNames("container", css.grid)}>
        <div className={css.text}>
          <span className="uppercase">{_.team}</span>
          <h2>{_.home_team_title}</h2>
          <LinkButton
            className={css.button}
            type="primary"
            url={getLocalizedUrl("/about")}
          >
            {_.our_team}
          </LinkButton>
        </div>
        <div className={css.slider}>
          <Swiper
            className={css.swiper}
            loop={true}
            onActiveIndexChange={() => setActiveSlide(slider?.realIndex)}
            onInit={swiper => {
              setSlider(swiper)
              setActiveSlide(0)
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <img src={slide} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={css.sliderButtonContainer}>
            <div
              className={classNames(css.container, "container", "overflow-visible")}
            >
              <button
                className={classNames(css.sliderButton, css.prev)}
                onClick={() => slider.slidePrev()}
              >
                <Icon name="chevron-right" />
              </button>
              <button
                className={classNames(css.sliderButton, css.next)}
                onClick={() => slider.slideNext()}
              >
                <Icon name="chevron-right" />
              </button>
            </div>
          </div>

          <div className={css.sliderNav}>
            {slides.map((slide, index) => (
              <span
                key={index}
                onClick={() => slider.slideToLoop(index)}
                className={classNames(
                  css.button,
                  index === activeSlide ? css.active : ""
                )}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
