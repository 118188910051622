import * as React from "react"
import * as css from "./collaborations-carousel-screen.module.scss"
import { CollaborationSlide } from "./collaboration-slide"
import { useTranslation } from "../../../common/use-translations/use-translations"
import classNames from "classnames"

export const CollaborationsCarouselSlide = ({
  collaboration,
} : {
  collaboration: CollaborationSlide
}) => {
  const _ = useTranslation()
  return (
    <div className="container overflow-visible">
      <span className={`uppercase ${css.title}`}>
        {_.our_collaborations}
      </span>
      <div className={classNames(css.grid, { [css.background]: collaboration.reverse })}>
        {collaboration.reverse ? (
          <>
            <div className={css.text}>
              <h2 dangerouslySetInnerHTML={{ __html: _[collaboration.title] }} />
              <p dangerouslySetInnerHTML={{ __html: _[collaboration.description] }} />
            </div>
            <div className={classNames(css.image, { [css.imageCenter]: collaboration.reverse })}>
              <picture>
                <source media="(max-width: 768px)" srcSet={collaboration.imageMb} />
                <source media="(max-width: 1024px)" srcSet={collaboration.imageTab} />
                <img src={collaboration.image} alt={collaboration.title} />
              </picture>
            </div>
          </>
        ) : (
          <>
            <div className={css.image}>
              <picture>
                <source media="(max-width: 768px)" srcSet={collaboration.imageMb} />
                <source media="(max-width: 1024px)" srcSet={collaboration.imageMb} />
                <img src={collaboration.image} alt={collaboration.title} />
              </picture>
            </div>
            <div className={css.text}>
              <h2 dangerouslySetInnerHTML={{ __html: _[collaboration.title] }} />
              <p dangerouslySetInnerHTML={{ __html: _[collaboration.description] }} />
            </div>
          </>
        )}
        
      </div>
    </div>
  )
}
